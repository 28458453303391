import React, { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    Grid,
    Drawer,
    Box
} from '@mui/material';
import { useLocalStorage, useReadLocalStorage } from 'usehooks-ts';
import { useNavigate, useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const RunScreen: React.FC = () => {
    const initialJwtToken = useReadLocalStorage('fidesium-jwt')
    const [jwtToken, setJwtToken] = useLocalStorage('fidesium-jwt', initialJwtToken)
    const [shouldVerifyJwtExists, setShouldVerifyJwtExists] = useState<boolean>(true)
    const navigate = useNavigate()
    const { repo_id, run_id } = useParams()
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [githubToken, setGithubToken] = useState(null)
    const [shouldGetGithubToken, setShouldGetGithubTokens] = useState<boolean>(false)
    const [githubRefreshToken, setGithubRefreshToken] = useState(null)
    const [repository, setRepository] = useState(null)
    const [shouldFetchRepository, setShouldFetchRepository] = useState(false)
    const [repositoryName, setRepositoryName] = useState('')
    const [activeIssue, setActiveIssue] = useState<any>(null)
    const [activeIssueChanged, setActiveIssueChanged] = useState<boolean>(false)
    const [shouldFetchIssues, setShouldFetchIssues] = useState<boolean>(false)
    const [issues, setIssues] = useState<Array<any>>([])
    const [commitSha, setCommitSha] = useState<string>('')

    useEffect(() => {
        if (shouldVerifyJwtExists) {
            if ((jwtToken === null) || (jwtToken === '') || (jwtToken === undefined) || (typeof jwtToken !== 'string')) {
                navigate('/login')
                setShouldVerifyJwtExists(false)
            } else {
                try {
                    const decodedJwt = jwtDecode(jwtToken) as any
                    if(decodedJwt.githubToken) {
                        setGithubToken(decodedJwt.githubToken)
                        setGithubRefreshToken(decodedJwt.githubRefreshToken)
                        setShouldVerifyJwtExists(false)
                    } else {
                        setShouldGetGithubTokens(true)
                    }

                    
                } catch (e) {
                    setJwtToken(null)
                    setShouldVerifyJwtExists(true)
                }
            }
        }
    }, [shouldVerifyJwtExists, jwtToken])

    useEffect(() => {
        fetchGithubUserToken()
        setShouldGetGithubTokens(false)
    }, [shouldGetGithubToken])

    const fetchGithubUserToken = async () => {
        const githubTokenResponse = await fetch(`${process.env.REACT_APP_FIDESIUM_URL}/auth/githubTokens`, {
            method: 'GET',
            headers: {
                'Authorization': jwtToken as string
            }
        })
        if (githubTokenResponse.ok){
            const githubTokenResponseData = await githubTokenResponse.json()
            setGithubToken(githubTokenResponseData.githubToken)
            setGithubRefreshToken(githubTokenResponseData.githubRefreshToken)
            setShouldFetchRepository(true)
        }
    }

    useEffect(() => {
        if (shouldFetchRepository) {
            fetchRepository()
            setShouldFetchRepository(false)
        }
    }, [shouldFetchRepository])

    const fetchRepository = async () => {
        const repositoryResponse = await fetch(`${process.env.REACT_APP_FIDESIUM_URL}/api/repository`, {
            method: 'POST',
            body: JSON.stringify({
                githubToken,
                githubRefreshToken,
                repoId: repo_id
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwtToken as string
            }
        })
        if (repositoryResponse.ok) {
            const repositoryResponseData = await repositoryResponse.json()
            setRepository(repositoryResponseData.repository)
            setRepositoryName(repositoryResponseData.repository.name)
            setShouldFetchIssues(true)
        }
    }

    useEffect(() => {
        if(shouldFetchIssues) {
            fetchIssues()
            setShouldFetchIssues(false)
        }
    }, [shouldFetchIssues])

    const fetchIssues = async () => {
        const issuesResponse = await fetch(`${process.env.REACT_APP_FIDESIUM_URL}/api/runs/${run_id}/issues`, {
            headers: {
                'Authorization': jwtToken as string
            }
        })
        if (issuesResponse.ok) {
            const data = await issuesResponse.json()
            setIssues(data.issues)
            setCommitSha(data.commitSha)
        }
    }


    const renderDrawer = () => {
        if((activeIssue === null) && (drawerOpen === true)) {
            setDrawerOpen(false)
        } else if ((activeIssue !== null) && (drawerOpen === true)){
            return(<Box sx={{width: 350}} className="issueDetail" role="presentation">
                <button onClick={() => {setDrawerOpen(false); setActiveIssueChanged(false); setActiveIssue(null)}}>Close Drawer</button>
                <p><b>Issue Type:</b> {activeIssue.type}</p>
                <p><b>Issue Severity:</b> {activeIssue.severity}</p>
                <p><b>Issue Summary:</b> {activeIssue.summary}</p>
                <p><b>Fidesium Recommends:</b></p>
                <ul>
                    {activeIssue.fixRecommendations.map((fix: any, index: number) => <li key={index}>{fix}</li>)}
                </ul>
                <p><b>Issue Details:</b></p>
                <ul>
                    {activeIssue.details.map((det: any, index: number) => <li key={index}>{det}</li>)}
                </ul>
                <p><b>Your Responses:</b> 
                    <ul>
                        {activeIssue.responses.map((res: any, index: number) => <li key={index}>{res}</li>)}
                    </ul>
                    <p><b>Add a new Response:</b>  <input onChange={() => setActiveIssueChanged(true)} type="textfield"></input></p>
                </p>
                <p><b>Your Status:</b>  {activeIssue.responseStatus}</p>
                <p><b>Change Status:</b>  <select onChange={() => setActiveIssueChanged(true)}><option>Won&rsquo;t Fix</option><option>Acknowledged</option><option>Active</option></select></p>
                {activeIssueChanged ? <button>Save Changes</button> : null}
            </Box>)
        }
    }


    return (
        <Card elevation={3} className="repoCard">
            <CardContent>
                <Grid container spacing={1} component="main">
                    {(repository === null) ? 
                        <div>Fetching repository</div> :
                        <Grid item xs={8}>
                            <b>{repositoryName}</b>
                            <b>Commit: {commitSha}</b>
                        </Grid>
                    }
                    <Grid item xs={3}>
                        <button>Generate Audit</button>
                    </Grid>
                </Grid>
                {issues.map((issue:any, index: number) => {
                    return(<Grid key={index} container spacing={1} className="issues" onClick={() => {setDrawerOpen(true); setActiveIssue(issue)}}>
                        <Grid item xs={4} sm={2}>Type:</Grid>
                        <Grid item xs={8} sm={4}>{issue.type}</Grid>
                        <Grid item xs={4} sm={2}>Severity:</Grid>
                        <Grid item xs={8} sm={4}>{issue.severity}</Grid>
                    </Grid>)
                })}
            </CardContent>
            <Drawer
                anchor={'right'}
                open={drawerOpen}
                onClose={() => {setDrawerOpen(false); setActiveIssueChanged(false); setActiveIssue(null)}}
            >
                {renderDrawer()}
            </Drawer>
        </Card>
    );
};

export default RunScreen;