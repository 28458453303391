import React, { useEffect } from 'react';
import { useLocalStorage, useReadLocalStorage } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';

const LogoutScreen: React.FC = () => {
    const initialJwtToken = useReadLocalStorage('fidesium-jwt')
    const [_, setJwtToken] = useLocalStorage('fidesium-jwt', initialJwtToken)
    const navigate = useNavigate();

    useEffect(() => {
        setJwtToken(null)
        navigate(`/auth/login`);
    });

    return (
        <></>
    );
};

export default LogoutScreen;