import { Outlet } from "react-router-dom"

import flb from './assets/fidesium-logo-black.png'
import { AppProvider, Navigation } from '@toolpad/core/AppProvider';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GitHubIcon from '@mui/icons-material/GitHub';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useReadLocalStorage } from "usehooks-ts";

const NAVIGATION: Navigation = [
    {
        kind: 'header',
        title: 'Main',
    },
    {
        segment: 'dashboard',
        title: 'Dashboard',
        icon: <DashboardIcon />,
    },
    {
        kind: 'divider',
    },
    {
        kind: 'header',
        title: 'Settings',
    },
    {
        segment: 'github',
        title: 'GitHub',
        icon: <GitHubIcon />,
    },
    {
        segment: 'account',
        title: 'Account',
        icon: <ManageAccountsIcon />,
        children: [
            {
                segment: 'settings',
                title: 'Settings',
                icon: <SettingsIcon />,
            },
            {
                segment: 'logout',
                title: 'Logout',
                icon: <LogoutIcon />,
            },
        ],
    },

];

const BRANDING = {
    title: ' ',
    logo: <img src={flb} alt="Fidesium logo" />
};


const App = () => {
    const jwtToken = useReadLocalStorage('fidesium-jwt')

    return (jwtToken === null) || (jwtToken === undefined) || (jwtToken === '') ?
        (<AppProvider branding={BRANDING}>
            <Outlet />
        </AppProvider>) :
        (<AppProvider navigation={NAVIGATION} branding={BRANDING}>
            <Outlet />
        </AppProvider>)
}
export default App