import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Web3 from 'web3'
import { Web3ReactProvider } from '@web3-react/core'
import * as Sentry from "@sentry/react";
import ErrorState from './components/ErrorState';
import { RouterProvider } from "react-router-dom";
import ReactGA from "react-ga4";
import router from './router'

if (process.env.REACT_APP_NODE_ENV === 'production') {
    Sentry.init({
        dsn: "https://1c67343eafd844f1a942aa1a90f153d2@o4504884732690432.ingest.sentry.io/4505193282666496",
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0
    });
} else {
    console.log('not in production so no sentry')
}

const getLibrary = (provider) => {
    return new Web3(provider)
}

ReactGA.initialize([{
    trackingId: "G-MJJ4WVZQ8G"
}])



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Sentry.ErrorBoundary fallback={<ErrorState></ErrorState>}>
        <React.StrictMode>
            <Web3ReactProvider getLibrary={getLibrary}>
                <RouterProvider router={router} />
            </Web3ReactProvider>
        </React.StrictMode>
    </Sentry.ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
