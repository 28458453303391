
import React, { useEffect, useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    TextField,
    Button,
    Alert,
    Grid,
    CircularProgress
} from '@mui/material';
import { useLocalStorage, useReadLocalStorage } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

interface LoginFormData {
  email: string;
  password: string;
}

const LoginScreen: React.FC = () => {

    const [formData, setFormData] = useState<LoginFormData>({
        email: '',
        password: ''
    });
    const [shouldNavigateToLoggedIn, setShouldNavigateToLoggedIn] = useState<boolean>(false)
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const initialJwtToken = useReadLocalStorage('fidesium-jwt')
    const [jwtToken, setJwtToken] = useLocalStorage('fidesium-jwt', initialJwtToken)
    const navigate = useNavigate();
    const [shouldVerifyJwtExists, setShouldVerifyJwtExists] = useState<boolean>(true)
    
    useEffect(() => {
        if (shouldVerifyJwtExists) {
            if ((jwtToken === null) || (jwtToken === '') || (jwtToken === undefined) || (typeof jwtToken !== 'string')) {
                setShouldVerifyJwtExists(false)
            } else {
                try {
                    jwtDecode(jwtToken)
                    setShouldNavigateToLoggedIn(true)
                    setShouldVerifyJwtExists(false)
                } catch (e) {
                    setJwtToken(null)
                    setShouldVerifyJwtExists(false)
                }
            }
        }
    }, [shouldVerifyJwtExists, jwtToken])

    useEffect(() => {
        if (shouldNavigateToLoggedIn) {
            navigate(`/dashboard`);
        }
    }, [shouldNavigateToLoggedIn]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    useEffect(() => {
        if (shouldNavigateToLoggedIn) {
            navigate(`/dashboard`);
        }
    }, [shouldNavigateToLoggedIn]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_FIDESIUM_URL}/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                throw new Error('Login failed. Please check your credentials.');
            }

            const responseBody = await response.json();

            setJwtToken(responseBody.token)
            setShouldNavigateToLoggedIn(true)
            // Handle successful login here (e.g., store token, redirect)
            // console.log('Login successful:', data);
      
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred during login');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Grid container component="main" className="login" sx={{justifyContent: "center", alignItems: "center",}}>
            <Grid item xs={12} sm={8} md={6} lg={4}>
                <Card elevation={3}>
                    <CardContent>
                        <h2>Sign in to your account</h2>
                        <p>Do not have a Fidesium account yet? <a href="/auth/signup">Sign up now</a></p>
            
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={formData.email}
                                onChange={handleInputChange}
                                sx={{ mb: 2 }}
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={formData.password}
                                onChange={handleInputChange}
                                sx={{ mb: 2 }}
                            />

                            {error && (
                                <Alert severity="error" sx={{ mb: 2 }}>
                                    {error}
                                </Alert>
                            )}

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={loading}
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {loading ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : (
                                    'Login'
                                )}
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default LoginScreen;