import '../../App.scss';
import fl from '../../assets/fidesium-logo-white.png'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import {JSX, ReactNode} from 'react'
import AssessmentTab from '../../components/AssessmentTab/AssessmentTab';
import AssessmentTabList from '../../components/AssessmentTabList/AssessmentTabList';

const renderVulnerabilities = (): ReactNode => {
    return(<>

        <Grid className="split" item xs={2}>
            <h4 className="ninety-bg">Critical</h4>
            
            <p>Total:  <span>1</span></p>
            <p>Active:  <span>0</span></p>
        </Grid>
        <Grid className="split" item xs={2}>
            <h4 className="sixty-bg">High</h4>
            
            <p>Total:  <span>2</span></p>
            <p>Active:  <span>0</span></p>
        </Grid>

        <Grid className="split" item xs={2}>
            <h4 className="thirty-bg">Medium</h4>
            
            <p>Total:  <span>8</span></p>
            <p>Active:  <span>4</span></p>
        </Grid>

        <Grid className="split" item xs={2}>
            <h4 className="ten-bg">Low</h4>
            
            <p>Total:  <span>6</span></p>
            <p>Active:  <span>5</span></p>
        </Grid>

        <Grid className="split" item xs={2}>
            <h4 className="na-bg">Info</h4>
            
            <p>Total:  <span>3</span></p>
            <p>Active:  <span>3</span></p>
        </Grid>

    </>)
}

const renderReport = (): JSX.Element => {
    return (
        <AssessmentTabList activeTabIndex={0}>
            <AssessmentTab score={12} label={'Report Summary'}>
                <Grid className="contentBox" item xs={12}>
                    <Grid container>
                        <Grid className="field" item xs={2}>Report on:</Grid>
                        <Grid className="data" item xs={5}>PBJ Consulting</Grid>

                        <Grid className="field" item xs={2}>Report number:</Grid>
                        <Grid className="data" item xs={3}>{'Initial assessment'}</Grid>

                        <Grid className="field" item xs={2}>Report hash:</Grid>
                        <Grid className="data" item xs={5}>f405422b450a98ede48ef0f76d9d7d44ee769fa6</Grid>

                        <Grid className="field" item xs={2}>Risk summary:</Grid>
                        <Grid className="data" item xs={3}><span className="riskScore-sm twenty-bg"><span>12</span></span></Grid>

                        <Grid className="field" item xs={2}>Repository:</Grid>
                        <Grid className="data" item xs={5}>https://github.com/PB-JWeb3/vesting-contracts</Grid>
                    </Grid>
                </Grid>
            </AssessmentTab>

            <AssessmentTab score={1} label={'Team Risk'}>

                <Grid className="header" item xs={12} sm={3} lg={2}>Team Risk</Grid>
                <Grid className="contentBox" item xs={12} sm={9} lg={10}>
                    <Grid container>
                        <Grid className="field" item xs={4} lg={3}>Team doxxing status: </Grid>
                        <Grid className="data" item xs={8} lg={9}>{'public'}</Grid>
                        <Grid className="field" item xs={4} lg={3}>Team experience:</Grid>
                        <Grid className="data" item xs={8} lg={9}>relevant</Grid>
                    </Grid>
                </Grid>
            </AssessmentTab>

            <AssessmentTab score={12} label={'Contract Risk'}>

                <Grid className="header" item xs={12} sm={3} lg={2}>Contract Risk</Grid>
                <Grid className="contentBox" item xs={12} sm={9} lg={10}>
                    <Grid container>
                        {renderVulnerabilities()}
                    </Grid>
                </Grid>
            </AssessmentTab>
        </AssessmentTabList>
    )
}

const PBJRedactedAssessmentScreen = (): JSX.Element => {
    return (
        <div className="wrapper">
            <AppBar className="newAppBar" elevation={0}>
                <Toolbar className="newToolBar">
                    <Box className="logo"><img className="logo" src={fl} alt="Fidesium logo"/></Box>
                    <Box className="title"><span>Risk Posture Audits</span></Box>
                </Toolbar>
            </AppBar>
            <Grid container spacing={2} my={8} className="auditBody" justifyContent="center" alignItems="center">
                {renderReport()}
            </Grid>
        </div>)

}

export default PBJRedactedAssessmentScreen;