import React, { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    Grid,
    Chip,
    LinearProgress  
} from '@mui/material';
import { useLocalStorage, useReadLocalStorage } from 'usehooks-ts';
import { useNavigate, useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const RepositoryScreen: React.FC = () => {
    const initialJwtToken = useReadLocalStorage('fidesium-jwt')
    const [jwtToken, setJwtToken] = useLocalStorage('fidesium-jwt', initialJwtToken)
    const [shouldVerifyJwtExists, setShouldVerifyJwtExists] = useState<boolean>(true)
    const navigate = useNavigate()
    const { repo_id } = useParams()
    const [githubToken, setGithubToken] = useState(null)
    const [shouldGetGithubToken, setShouldGetGithubTokens] = useState<boolean>(false)
    const [githubRefreshToken, setGithubRefreshToken] = useState(null)
    const [repository, setRepository] = useState(null)
    const [shouldFetchRepository, setShouldFetchRepository] = useState(false)
    const [repositoryName, setRepositoryName] = useState('')
    const [runs, setRuns] = useState<any>([])
    const [shouldFetchRuns, setShouldFetchRuns] = useState(false)
    const [pollRunStatusId, setPollRunStatusId] = useState(null)
    const [runStatus, setRunStatus] = useState(null);
    const [processedRuns, setProcessedRuns] = useState<any>({})

    useEffect(() => {
        if (shouldVerifyJwtExists) {
            if ((jwtToken === null) || (jwtToken === '') || (jwtToken === undefined) || (typeof jwtToken !== 'string')) {
                navigate('/login')
                setShouldVerifyJwtExists(false)
            } else {
                try {
                    const decodedJwt = jwtDecode(jwtToken) as any
                    if(decodedJwt.githubToken) {
                        setGithubToken(decodedJwt.githubToken)
                        setGithubRefreshToken(decodedJwt.githubRefreshToken)
                        setShouldVerifyJwtExists(false)
                    } else {
                        setShouldGetGithubTokens(true)
                    }

                    
                } catch (e) {
                    setJwtToken(null)
                    setShouldVerifyJwtExists(true)
                }
            }
        }
    }, [shouldVerifyJwtExists, jwtToken])

    useEffect(() => {
        fetchGithubUserToken()
        setShouldGetGithubTokens(false)
    }, [shouldGetGithubToken])

    const fetchGithubUserToken = async () => {
        const githubTokenResponse = await fetch(`${process.env.REACT_APP_FIDESIUM_URL}/auth/githubTokens`, {
            method: 'GET',
            headers: {
                'Authorization': jwtToken as string
            }
        })
        if (githubTokenResponse.ok){
            const githubTokenResponseData = await githubTokenResponse.json()
            setGithubToken(githubTokenResponseData.githubToken)
            setGithubRefreshToken(githubTokenResponseData.githubRefreshToken)
            setShouldFetchRepository(true)
        }
    }

    useEffect(() => {
        if (shouldFetchRepository) {
            fetchRepository()
            setShouldFetchRepository(false)
            setShouldFetchRuns(true)
        }
    }, [shouldFetchRepository])

    const fetchRepository = async () => {
        const repositoryResponse = await fetch(`${process.env.REACT_APP_FIDESIUM_URL}/api/repository`, {
            method: 'POST',
            body: JSON.stringify({
                githubToken,
                githubRefreshToken,
                repoId: repo_id
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwtToken as string
            }
        })
        if (repositoryResponse.ok) {
            const repositoryResponseData = await repositoryResponse.json()
            setRepository(repositoryResponseData.repository)
            setRepositoryName(repositoryResponseData.repository.name)
        }
    }

    useEffect(() => {
        if (shouldFetchRuns && repository) {
            fetchRunsWithIssues()
            setShouldFetchRuns(false)
        }
    }, [shouldFetchRuns, repository])

    const fetchRunsWithIssues = async () => {
        const runsResponse = await fetch(`${process.env.REACT_APP_FIDESIUM_URL}/api/repos/${(repository as any).id}/runs`, {
            headers: {
                'Authorization': jwtToken as string
            }
        })
        if (runsResponse.ok) {
            const runsData = await runsResponse.json()
            setRuns(runsData.runs)
            const pruns = runsData.runs.reduce((memo: any, run: any) => {
                const keys = Object.keys(memo)
                if (keys.includes(run.run_id)) {
                    const newIssues = [...memo[run.run_id].issues, {...run}]
                    return {
                        ...memo,
                        [run.run_id]: {issues: newIssues, commit_sha: run.commit_sha}
                    }
                } else {
                    return {
                        ...memo,
                        [run.run_id]: {issues: [{...run}], commit_sha: run.commit_sha}
                    }
                }
            }, {})
            const enrichedPruns = Object.keys(pruns).reduce((memo: any, prunKey: any) => {
                const counts = pruns[prunKey].issues.reduce((innerMemo: any, issue: any ) => {
                    if (issue.criticality === 'critical') {
                        return {
                            ...innerMemo,
                            criticals: innerMemo.criticals + 1
                        }
                    } else if (issue.criticality === 'high') {
                        return {
                            ...innerMemo,
                            high: innerMemo.high + 1
                        }
                    } else if (issue.criticality === 'medium') {
                        return {
                            ...innerMemo,
                            medium: innerMemo.medium + 1
                        }
                    } else if (issue.criticality === 'low') {
                        return {
                            ...innerMemo,
                            low: innerMemo.low + 1
                        }
                    } else if (issue.criticality === 'info') {
                        return {
                            ...innerMemo,
                            info: innerMemo.info + 1
                        }
                    } else {
                        return memo
                    }
                }, {criticals: 0, high: 0, medium: 0, low: 0, info: 0})
                return {
                    ...memo,
                    [prunKey]: {...memo[prunKey], ...counts}
                }
            }, pruns)
            setProcessedRuns(enrichedPruns)
        }
    }

    const analyze = async () => {
        const runResponse =  await fetch(`${process.env.REACT_APP_FIDESIUM_URL}/api/runs`, {
            method: 'POST',
            body: JSON.stringify({
                repoId: repo_id,
                githubToken: githubToken
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwtToken as string
            }
        })

        if (runResponse.ok) {
            const runData = await runResponse.json()
            const runList = [{running: true, id: runData.runId, commit: runData.commit, issues: []}, ...runs]
            setRuns(runList)

            await fetch(`${process.env.REACT_APP_FIDESIUM_URL}/api/github/analyze`, {
                method: 'POST',
                body: JSON.stringify({
                    runId: runData.runId,
                    githubToken: githubToken
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken as string
                }
            })

            setPollRunStatusId(runData.runId)
        }
    }

    useEffect(() => {
        const controller = new AbortController();
        let timeoutId: any = null;
    
        const pollStatus = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_FIDESIUM_URL}/api/run/${pollRunStatusId}`,
                    {
                        signal: controller.signal,
                        headers: {
                            'Authorization': jwtToken as string
                        }
                    }
                );
    
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
    
                const data = await response.json();
                setRunStatus(data.run.run_status);
    
                // Continue polling if status is not terminal
                if (data.run.run_status !== 'finished' && data.run.run_status !== 'failed') {
                    timeoutId = setTimeout(pollStatus, 20000);
                } else {
                    setPollRunStatusId(null)
                }
            } catch (err: any) {
                if (err.name === 'AbortError') {
                    return;
                }
            }
        };
        if(pollRunStatusId) {
            pollStatus();
        }
    
        return () => {
            controller.abort();
            if (timeoutId) {
                clearTimeout(timeoutId);
                setShouldFetchRuns(true)
            }
        };
    }, [pollRunStatusId]);

    return (
        <Card elevation={3} className="repoCard">
            <CardContent>
                <Grid container spacing={1} component="main">
                    {(repository === null) ? 
                        <Grid item xs={10}>
                            <b>Fetching repository</b></Grid> :
                        <Grid item xs={10}>
                            <b>{repositoryName}</b>
                        </Grid>
                    }
                    <Grid item xs={2}>
                        <button onClick={() =>{analyze()}} disabled={runs[0]?.running}>{runs[0]?.running ? 'Processing' : 'Rerun'}{runs[0]?.running ? <LinearProgress />: null}</button>
                    </Grid>
                </Grid>
                {runs[0]?.running ? <><Grid item xs={4} sm={2} className='current'><Chip
                    size="small"
                    label={runs[0].commit_sha}
                    color="default"
                /></Grid> <Grid item xs={4} sm={2}>{runStatus ? runStatus : 'Run In Progress'}</Grid><LinearProgress /></> : null}
                {Object.keys(processedRuns).map((runKey: any) => {
                    return(<Grid key={runKey} container spacing={1} className="vunerabilities" onClick={() => {navigate(`/runs/${runKey}/repo/${repo_id}`)}}>
                        <Grid item xs={4} sm={2}><Chip
                            size="small"
                            label={processedRuns[runKey].commit_sha}
                            color="default"
                        /></Grid>
                        <Grid item xs={4} sm={2}>Criticals: 
                            <Chip
                                size="small"
                                label={processedRuns[runKey].criticals}
                                color="error"
                            /></Grid>
                        <Grid item xs={4} sm={2}>High: 
                            <Chip
                                size="small"
                                label={processedRuns[runKey].high}
                                color="warning"
                            /></Grid>
                        <Grid item xs={4} sm={2}>Medium: 
                            <Chip
                                size="small"
                                label={processedRuns[runKey].medium}
                                color="secondary"
                            /></Grid>
                        <Grid item xs={4} sm={2}>Low: 
                            <Chip
                                size="small"
                                label={processedRuns[runKey].low}
                                color="success"
                            /></Grid>
                        <Grid item xs={4} sm={2}>Info: 
                            <Chip
                                size="small"
                                label={processedRuns[runKey].info}
                                color="default"
                            /></Grid>
                    </Grid>)
                })}
            </CardContent>
        </Card>
    );
};

export default RepositoryScreen;