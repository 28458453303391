import React, { useState, useEffect } from 'react';
import { 
    TextField,
    Checkbox,
    Button,
    Card,
    CardContent,
    Alert,
    Box,
    Grid
} from '@mui/material';
import { useLocalStorage, useReadLocalStorage } from 'usehooks-ts'
import { useNavigate } from "react-router-dom"
import { jwtDecode } from 'jwt-decode';

const SignupForm = () => {
    const [shouldNavigateToLoggedIn, setShouldNavigateToLoggedIn] = useState<boolean>(false)
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: ''
    });
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const initialJwtToken = useReadLocalStorage('fidesium-jwt')
    const [jwtToken, setJwtToken] = useLocalStorage('fidesium-jwt', initialJwtToken)
    const navigate = useNavigate();
    const [shouldVerifyJwtExists, setShouldVerifyJwtExists] = useState<boolean>(true)
    
    useEffect(() => {
        if (shouldVerifyJwtExists) {
            if ((jwtToken === null) || (jwtToken === '') || (jwtToken === undefined) || (typeof jwtToken !== 'string')) {
                setShouldVerifyJwtExists(false)
            } else {
                try {
                    jwtDecode(jwtToken)
                    setShouldNavigateToLoggedIn(true)
                    setShouldVerifyJwtExists(false)
                } catch (e) {
                    setJwtToken(null)
                    setShouldVerifyJwtExists(false)
                }
            }
        }
    }, [shouldVerifyJwtExists, jwtToken])

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        setError('');
    };

    useEffect(() => {
        if (shouldNavigateToLoggedIn) {
            navigate(`/dashboard`);
        }
    }, [shouldNavigateToLoggedIn]);

    const [tocAccepted, settocAccepted] = useState(false);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        settocAccepted(event.target.checked);
    };

    const validateForm = () => {
        if (!formData.email || !formData.password || !formData.confirmPassword) {
            setError('All fields are required');
            return false;
        }

        if (formData.password !== formData.confirmPassword) {
            setError('Passwords do not match');
            return false;
        }

        if (formData.password.length < 8) {
            setError('Password must be at least 8 characters long');
            return false;
        }

        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            setError('Please enter a valid email address');
            return false;
        }

        return true;
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
    
        if (!validateForm()) return;

        setIsSubmitting(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_FIDESIUM_URL}/auth/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: formData.email,
                    password: formData.password,
                }),
            });

            if (!response.ok) {
                throw new Error('Signup failed');
            }

            const responseBody = await response.json()
            setJwtToken(responseBody.token)
            setShouldNavigateToLoggedIn(true)

            // Reset form on success
            setFormData({ email: '', password: '', confirmPassword: '' });
            setError('');
            // You might want to redirect or show a success message here
        } catch (err) {
            setError('Failed to create account. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Grid container className="signup" sx={{justifyContent: "center", alignItems: "center",}}>
            <Grid item xs={12} sm={8} md={6} lg={4}>
                <Card elevation={3}>
                    <CardContent>
                        <h2>Sign Up</h2>
                        <p>Already have a Fidesium account? <a href="/auth/login">Sign in</a></p>
                        <Box
                            component="form"
                            onSubmit={handleSubmit}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            }}
                        >
                            <TextField
                                fullWidth
                                label="Email"
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                variant="outlined"
                                required
                            />
                            <TextField
                                fullWidth
                                label="Password"
                                type="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                variant="outlined"
                                required
                            />
                            <p className="helper">Min. 8 characters long, contains at least 1 uppercase letter and 1 number</p>
                            <TextField
                                fullWidth
                                label="Confirm Password"
                                type="password"
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                variant="outlined"
                                required
                            />
                            <p><Checkbox checked={tocAccepted} onChange={handleCheckboxChange}/> Accept <a href="" target="_blank">Terms</a> and <a href="" target="_blank">Privacy Policy</a></p>
                            {error && (
                                <Alert severity="error" sx={{ mt: 2 }}>
                                    {error}
                                </Alert>
                            )}

                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                                disabled={isSubmitting || !tocAccepted}
                                sx={{ mt: 2 }}
                            >
                                {isSubmitting ? 'Creating Account...' : 'Sign Up'}
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default SignupForm;