import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import flb from '../../assets/fidesium-logo-black.png'
import Button from '@mui/material/Button';

const Screen404 = () => {

    return (
        <Container maxWidth="sm">
            <Grid container className='error-code' my={2}>
                <Grid item xs={12}><img className="logo" src={flb} alt="Fidesium logo"/></Grid>
                <Grid item xs={12} className='error-text'>
                    <Box><h1>404</h1></Box>
                    <Box><h4>You have reached this page in error</h4></Box>
                </Grid>
            </Grid>
            <Grid container my={2}>
                <Button variant="contained" color="primary" fullWidth={true} size="large" onClick={() => window.location.href='/dashboard'}>Return to app</Button>
            </Grid>
        </Container>
    );
}

export default Screen404;
