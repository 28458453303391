import { useState, useEffect } from 'react';
import { 
    Card, 
    CardContent, 
    Typography, 
    Button, 
    List,
    ListItem,
    ListItemText,
    Paper,
    Box,
    Grid,
    Container,
    Chip,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Stack
} from '@mui/material';

import GitHubIcon from '@mui/icons-material/GitHub';
import BusinessIcon from '@mui/icons-material/Business';
import { jwtDecode } from 'jwt-decode';
import { useNavigate, useSearchParams } from "react-router-dom"
import { useReadLocalStorage } from 'usehooks-ts';

interface GitHubRepository {
    id: number;
    full_name: string;
    description: string | null;
    private: boolean;
    html_url: string;
    owner: {
      login: string;
      id: number;
    };
    name: string;
    repo_type: 'personal' | 'organization';
    org_name: string | null;
    updated_at: string;  
  }

  interface Organization {
    id: number;
    login: string;
    description: string | null;
  }

  interface Installation {
    id: number;
    account: {
      login: string;
      type: string;
      avatar_url: string;
    };
  }
  
  interface InstallationsState {
    personal: Installation | null;
    organizations: Installation[];
    totalInstallations: number;
  }

const GithubScreen = () => {
    const [searchParams] = useSearchParams()
    const urlGithubAccessToken = searchParams.get('github_access_token')
    const urlGithubRefreshToken = searchParams.get('github_refresh_token')
    const [jwtGithubAccessToken, setJwtGithubAccessToken] = useState(null)
    const [jwtGithubRefreshToken, setJwtGithubRefreshToken] = useState(null)
    const [shouldFetchGithubToken, setShouldFetchGithubToken] = useState<boolean>(false)
    const [shouldFetchGithubUser, setShouldFetchGithubUser] = useState<boolean>(false)
    const [githubAccessToken, setGithubAccessToken] = useState(urlGithubAccessToken || jwtGithubAccessToken)
    const [githubRefreshToken, setGithubRefreshToken] = useState(urlGithubRefreshToken || jwtGithubRefreshToken)
    const [githubUserName, setGithubUsername] = useState(null)
    const [githubUserImage, setGithubUserImage] = useState(null)
    const [shouldUpdateGithubTokensForUser, setShouldUpdateGithubTokensForUser] = useState<boolean>(true)
    const jwtToken = useReadLocalStorage('fidesium-jwt')
    const navigate = useNavigate();
    const [shouldVerifyJwtExists, setShouldVerifyJwtExists] = useState<boolean>(true)
    const [shouldFetchRepositories, setShouldFetchRepositories] = useState<boolean>(githubAccessToken !== undefined && githubAccessToken !== null && githubAccessToken !== '')
    const [repos, setRepos] = useState<ReadonlyArray<GitHubRepository>>([]);
    // const [selectedRepo, setSelectedRepo] = useState<GitHubRepository>();
    const [filter, setFilter] = useState('all'); // 'all', 'personal', 'organization'
    const [visibilityFilter, setVisibilityFilter] = useState('all'); // 'all', 'public', 'private'
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [selectedOrg, setSelectedOrg] = useState<string>('all');
    // const [comment, setComment] = useState('');
    // const [pullNumber, setPullNumber] = useState('');
    // const [loading, setLoading] = useState(false);
    const [installations, setInstallations] = useState<InstallationsState | null>(null);
    const [stats, setStats] = useState<any>(null);


    useEffect(() => {
        if (shouldVerifyJwtExists) {
            if ((jwtToken === null) || (jwtToken === '') || (jwtToken === undefined) || (typeof jwtToken !== 'string')) {
                navigate('/login')
            } else {
                try {
                    const decodedJwt = jwtDecode(jwtToken) as any
                    setJwtGithubAccessToken(decodedJwt.githubToken)
                    setJwtGithubRefreshToken(decodedJwt.githubRefreshToken)
                    setGithubAccessToken(urlGithubAccessToken || jwtGithubAccessToken)
                    setGithubRefreshToken(urlGithubRefreshToken || jwtGithubRefreshToken)
                    if (urlGithubAccessToken || jwtGithubAccessToken) {
                        setShouldFetchGithubUser(true)
                    } else {
                        setShouldFetchGithubToken(true)
                    }
                } catch (e) {
                    navigate('/login')
                }
            }
        }
        setShouldVerifyJwtExists(false)
    }, [shouldVerifyJwtExists, jwtToken, urlGithubAccessToken, jwtGithubAccessToken])

    useEffect(() => {
        if (!githubAccessToken && shouldFetchGithubToken) {
            fetchGithubUserToken()
            setShouldFetchGithubToken(false)
        }
    }, [shouldFetchGithubToken, githubAccessToken])

    const fetchGithubUserToken = async () => {
        if (githubAccessToken === null) {
            const githubTokenResponse = await fetch(`${process.env.REACT_APP_FIDESIUM_URL}/auth/githubTokens`, {
                method: 'GET',
                headers: {
                    'Authorization': jwtToken as string
                }
            })
            if (githubTokenResponse.ok){
                const githubTokenResponseData = await githubTokenResponse.json()
                setGithubAccessToken(githubTokenResponseData.githubToken)
                setGithubRefreshToken(githubTokenResponseData.githubRefreshToken)
                setJwtGithubAccessToken(githubTokenResponseData.githubToken)
                setJwtGithubRefreshToken(githubTokenResponseData.githubRefreshToken)
                setShouldFetchGithubUser(true)
            }
        }
    }

    useEffect(() => {
        if (!githubUserName && shouldFetchGithubUser) {
            fetchGithubUserData()
            setShouldFetchGithubUser(false)
        }
    }, [shouldFetchGithubUser, githubUserName])

    const fetchGithubUserData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_FIDESIUM_URL}/api/github/user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    githubAccessToken,
                    githubRefreshToken
                })
            })
            if (response.ok) {
                const data = await response.json();
                setGithubUserImage(data.githubData.avatar_url)
                setGithubUsername(data.githubData.login)
                setShouldFetchRepositories(true)
            }

        } catch (e) {
            console.log('failed to get user data')
        }
    }

    useEffect(() => {
        if (shouldUpdateGithubTokensForUser && urlGithubAccessToken && urlGithubRefreshToken && jwtToken) {
            updateGithubTokens()
            setShouldUpdateGithubTokensForUser(false)
        }
    }, [shouldUpdateGithubTokensForUser, urlGithubAccessToken, urlGithubRefreshToken, jwtToken])

    const updateGithubTokens = async () => {
        try {
            await fetch(`${process.env.REACT_APP_FIDESIUM_URL}/auth/storeGithubTokens`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken as string
                },
                body: JSON.stringify({
                    githubToken: githubAccessToken,
                    githubRefreshToken
                })
            });
        } catch (e) {
            setShouldUpdateGithubTokensForUser(true)
        }
    }

    useEffect(() => {
        try {
            if (shouldFetchRepositories) {
                checkInstallation()
                setShouldFetchRepositories(false)
            }
        } catch (e) {
            console.log(e)
        }
    }, [shouldFetchRepositories]);

    const checkInstallation = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_FIDESIUM_URL}/api/installation/status/${githubAccessToken}`);
            if (response.ok) {
                const data = await response.json();
                setInstallations(data);
                if (data.totalInstallations > 0) {
                    await fetchRepositories();
                    await fetchOrganizations()
                }
            }
        } catch (error) {
            console.error('Failed to check installation:', error);
        }
    };

    const fetchRepositories = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_FIDESIUM_URL}/api/repositories/${githubAccessToken}`);
            if(response.ok) {
                const data = await response.json();
                setRepos(data.repositories);
                setStats(data.stats);
            }
        } catch (error) {
            console.error('Failed to fetch repositories:', error);
        }
    };

    const fetchOrganizations = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_FIDESIUM_URL}/api/organizations/${githubAccessToken}`);
            const data: Organization[] = await response.json();
            setOrganizations(data);
        } catch (error) {
            console.error('Failed to fetch organizations:', error);
        }
    };

    const filterRepos = (repositories: ReadonlyArray<GitHubRepository>) => {
        return repositories.filter(repo => {
            const matchesType = 
            filter === 'all' || 
            (filter === 'personal' && repo.repo_type === 'personal') ||
            (filter === 'organization' && repo.repo_type === 'organization');
    
            const matchesVisibility =
            visibilityFilter === 'all' ||
            (visibilityFilter === 'public' && !repo.private) ||
            (visibilityFilter === 'private' && repo.private);
    
            const matchesOrg =
            selectedOrg === 'all' ||
            repo.org_name === selectedOrg;
    
            return matchesType && matchesVisibility && matchesOrg;
        });
    };

    const handleLogin = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_FIDESIUM_URL}/github/auth`);
            const data = await response.json();
            window.location.href = data.url;
            setShouldFetchRepositories(true)
        } catch (error) {
            console.error('Failed to initiate GitHub login:', error);
        }
    };

    // const handleClone = async () => {
    //     if (!selectedRepo) return;
    
    //     setLoading(true);
    //     try {
    //         await fetch(`${process.env.REACT_APP_FIDESIUM_URL}/api/clone`, {
    //             method: 'POST',
    //             headers: { 'Content-Type': 'application/json' },
    //             body: JSON.stringify({ repoFullName: selectedRepo.full_name }),
    //         });
    //         alert('Repository cloned successfully!');
    //     } catch (error) {
    //         console.error('Failed to clone repository:', error);
    //         alert('Failed to clone repository');
    //     }
    //     setLoading(false);
    // };

    // const handleComment = async () => {
    //     if (!selectedRepo || !comment || !pullNumber) return;

    //     setLoading(true);
    //     try {
    //         const [owner, repo] = selectedRepo.full_name.split('/');
    //         await fetch(`${process.env.REACT_APP_FIDESIUM_URL}/api/comment`, {
    //             method: 'POST',
    //             headers: { 'Content-Type': 'application/json' },
    //             body: JSON.stringify({
    //                 owner,
    //                 repo,
    //                 pullNumber: parseInt(pullNumber),
    //                 comment
    //             }),
    //         });
    //         alert('Comment added successfully!');
    //         setComment('');
    //         setPullNumber('');
    //     } catch (error) {
    //         console.error('Failed to add comment:', error);
    //         alert('Failed to add comment');
    //     }
    //     setLoading(false);
    // };

    return (
        <Container className="mainContent" maxWidth="md" sx={{ py: 4 }}>
            <Card elevation={3}>
                <CardContent>
                    <Typography variant="h4" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <GitHubIcon fontSize="large" />
            GitHub Repository Manager
                    </Typography>

                    {githubUserName ? 
                        <>
                            <Grid container>
                                <Grid item xs={4}><img className="gitUser" src={githubUserImage || undefined}></img></Grid>
                                <Grid item xs={8}><p>Logged in as: {githubUserName}</p></Grid>
                            </Grid>
                        </> :
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={handleLogin}
                            startIcon={<GitHubIcon />}
                            sx={{ mb: 3 }}
                        >
            Login with GitHub
                        </Button>}
                    {!installations?.totalInstallations && (
                        <Paper 
                            elevation={0} 
                            sx={{ 
                                p: 2, 
                                mb: 2, 
                                bgcolor: 'warning.light',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2
                            }}
                        >
                            <Typography>
                To access repositories, you need to install the GitHub App on your account
                                {installations?.organizations?.length ? ' or organizations' : ''}
                            </Typography>
                            <Button
                                variant="contained"
                                href={`https://github.com/apps/FidesiumPRScanner/installations/new`}
                                startIcon={<GitHubIcon />}
                            >
                Install GitHub App
                            </Button>
                        </Paper>
                    )}

                    {stats && (
                        <Paper sx={{ p: 2, mb: 3 }} variant="outlined">
                            <Typography variant="h6" gutterBottom>
                Repository Statistics
                            </Typography>
                            <Stack spacing={1}>
                                <Typography>
                  Total Repositories: {stats.total}
                                </Typography>
                                <Typography>
                  Private: {stats.byVisibility.private?.length || 0}
                                </Typography>
                                <Typography>
                  Public: {stats.byVisibility.public?.length || 0}
                                </Typography>
                            </Stack>
                        </Paper>
                    )}
                    <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                            <Typography variant="h6">Connected Repositories</Typography>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <FormControl size="small" sx={{ minWidth: 120 }}>
                                    <InputLabel>Type</InputLabel>
                                    <Select
                                        value={filter}
                                        label="Type"
                                        onChange={(e) => setFilter(e.target.value)}
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        <MenuItem value="personal">Personal</MenuItem>
                                        <MenuItem value="organization">Organization</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl size="small" sx={{ minWidth: 120 }}>
                                    <InputLabel>Visibility</InputLabel>
                                    <Select
                                        value={visibilityFilter}
                                        label="Visibility"
                                        onChange={(e) => setVisibilityFilter(e.target.value)}
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        <MenuItem value="public">Public</MenuItem>
                                        <MenuItem value="private">Private</MenuItem>
                                    </Select>
                                </FormControl>

                                {organizations.length > 0 && (
                                    <FormControl size="small" sx={{ minWidth: 120 }}>
                                        <InputLabel>Organization</InputLabel>
                                        <Select
                                            value={selectedOrg}
                                            label="Organization"
                                            onChange={(e) => setSelectedOrg(e.target.value)}
                                        >
                                            <MenuItem value="all">All</MenuItem>
                                            {organizations.map(org => (
                                                <MenuItem key={org.id} value={org.login}>
                                                    {org.login}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </Box>
                        </Box>

                        <List sx={{ maxHeight: 300, overflow: 'auto' }}>
                            {filterRepos(repos).map((repo) => (
                                <ListItem
                                    key={repo.id}
                                    // onClick={() => setSelectedRepo(repo)}
                                >
                                    <ListItemText
                                        primary={
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Typography variant="body1" component="span">
                                                    {repo.full_name}
                                                </Typography>
                                                {repo.private && (
                                                    <Chip
                                                        size="small"
                                                        label="Private"
                                                        color="default"
                                                    />
                                                )}
                                            </Stack>
                                        }
                                        secondary={
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Typography variant="body2" component="span">
                                                    {repo.description || 'No description available'}
                                                </Typography>
                                                {repo.repo_type === 'organization' && (
                                                    <Chip
                                                        size="small"
                                                        label={repo.org_name}
                                                        icon={<BusinessIcon />}
                                                    />
                                                )}
                                            </Stack>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Paper>

                    {/* {selectedRepo && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Button
                                variant="contained"
                                onClick={handleClone}
                                disabled={loading}
                                startIcon={<ContentCopyIcon />}
                            >
                Clone Repository
                            </Button>

                            <Divider sx={{ my: 2 }} />

                            <Typography variant="h6" gutterBottom>
                Add Comment to Pull Request
                            </Typography>

                            <TextField
                                type="number"
                                label="Pull Request Number"
                                value={pullNumber}
                                onChange={(e) => setPullNumber(e.target.value)}
                                fullWidth
                            />

                            <TextField
                                label="Comment"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                multiline
                                rows={3}
                                fullWidth
                            />

                            <Button
                                variant="contained"
                                onClick={handleComment}
                                disabled={loading || !comment || !pullNumber}
                                startIcon={<CommentIcon />}
                            >
                                {loading ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : (
                                    'Add Comment'
                                )}
                            </Button>
                        </Box>
                    )} */}
                </CardContent>
            </Card>
        </Container>
    );
};

export default GithubScreen;