import '../../Dashboard.scss';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage, useReadLocalStorage } from 'usehooks-ts';
import { jwtDecode } from 'jwt-decode';
import { 
    Typography,
    Box,
    Card,
    CardContent,
    MenuItem,
    FormControl,
    Chip,
    InputLabel,
    Select
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';

interface GitHubRepository {
    id: number;
    full_name: string;
    description: string | null;
    private: boolean;
    html_url: string;
    owner: {
      login: string;
      id: number;
    };
    name: string;
    repo_type: 'personal' | 'organization';
    org_name: string | null;
    updated_at: string;  
  }

  interface Organization {
    id: number;
    login: string;
    description: string | null;
  }

const DashboardScreen = () => {
    const initialJwtToken = useReadLocalStorage('fidesium-jwt')
    const [jwtToken, setJwtToken] = useLocalStorage('fidesium-jwt', initialJwtToken)
    const [githubToken, setGithubToken] = useState(null)
    const [shouldFetchRepositories, setShouldFetchRepositories] = useState(false)
    const [visibilityFilter, setVisibilityFilter] = useState('all'); // 'all', 'public', 'private'
    // const [installations, setInstallations] = useState(false)
    const [filter, setFilter] = useState('all'); // 'all', 'personal', 'organization'
    const [shouldGetGithubToken, setShouldGetGithubTokens] = useState<boolean>(false)
    const [_githubRefreshToken, setGithubRefreshToken] = useState(null)
    const navigate = useNavigate();
    const [shouldVerifyJwtExists, setShouldVerifyJwtExists] = useState<boolean>(true)
    const [repos, setRepos] = useState<ReadonlyArray<GitHubRepository>>([]);
    const [selectedOrg, setSelectedOrg] = useState<string>('all');
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    
    useEffect(() => {
        if (shouldVerifyJwtExists) {
            if ((jwtToken === null) || (jwtToken === '') || (jwtToken === undefined) || (typeof jwtToken !== 'string')) {
                navigate('/login')
                setShouldVerifyJwtExists(false)
            } else {
                try {
                    const decodedJwt = jwtDecode(jwtToken) as any
                    if(decodedJwt.githubToken) {
                        setGithubToken(decodedJwt.githubToken)
                        setGithubRefreshToken(decodedJwt.githubRefreshToken)
                        setShouldVerifyJwtExists(false)
                    } else {
                        setShouldGetGithubTokens(true)
                    }

                    
                } catch (e) {
                    setJwtToken(null)
                    setShouldVerifyJwtExists(true)
                }
            }
        }
    }, [shouldVerifyJwtExists, jwtToken])

    useEffect(() => {
        fetchGithubUserToken()
        setShouldGetGithubTokens(false)
    }, [shouldGetGithubToken])

    const fetchGithubUserToken = async () => {
        const githubTokenResponse = await fetch(`${process.env.REACT_APP_FIDESIUM_URL}/auth/githubTokens`, {
            method: 'GET',
            headers: {
                'Authorization': jwtToken as string
            }
        })
        if (githubTokenResponse.ok){
            const githubTokenResponseData = await githubTokenResponse.json()
            setGithubToken(githubTokenResponseData.githubToken)
            setGithubRefreshToken(githubTokenResponseData.githubRefreshToken)
            setShouldFetchRepositories(true)
        }
    }

    useEffect(() => {
        try {
            if (shouldFetchRepositories) {
                checkInstallation()
                setShouldFetchRepositories(false)
            }
        } catch (e) {
            console.log(e)
        }
    }, [shouldFetchRepositories]);

    const filterRepos = (repositories: ReadonlyArray<GitHubRepository>) => {
        return repositories.filter(repo => {
            const matchesType = 
            filter === 'all' || 
            (filter === 'personal' && repo.repo_type === 'personal') ||
            (filter === 'organization' && repo.repo_type === 'organization');
    
            const matchesVisibility =
            visibilityFilter === 'all' ||
            (visibilityFilter === 'public' && !repo.private) ||
            (visibilityFilter === 'private' && repo.private);
    
            const matchesOrg =
            selectedOrg === 'all' ||
            repo.org_name === selectedOrg;
    
            return matchesType && matchesVisibility && matchesOrg;
        });
    };

    const checkInstallation = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_FIDESIUM_URL}/api/installation/status/${githubToken}`);
            if (response.ok) {
                const data = await response.json();
                if (data.totalInstallations > 0) {
                    await fetchRepositories();
                    await fetchOrganizations()
                }
            }
        } catch (error) {
            console.error('Failed to check installation:', error);
        }
    };

    const fetchRepositories = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_FIDESIUM_URL}/api/repositories/${githubToken}`);
            if(response.ok) {
                const data = await response.json();
                setRepos(data.repositories);
            }
        } catch (error) {
            console.error('Failed to fetch repositories:', error);
        }
    };

    const fetchOrganizations = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_FIDESIUM_URL}/api/organizations/${githubToken}`);
            const data: Organization[] = await response.json();
            setOrganizations(data);
        } catch (error) {
            console.error('Failed to fetch organizations:', error);
        }
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid size={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography variant="h6">Connected Repositories</Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <FormControl size="small" sx={{ minWidth: 120 }}>
                                <InputLabel>Type</InputLabel>
                                <Select
                                    value={filter}
                                    label="Type"
                                    onChange={(e) => setFilter(e.target.value)}
                                >
                                    <MenuItem value="all">All</MenuItem>
                                    <MenuItem value="personal">Personal</MenuItem>
                                    <MenuItem value="organization">Organization</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl size="small" sx={{ minWidth: 120 }}>
                                <InputLabel>Visibility</InputLabel>
                                <Select
                                    value={visibilityFilter}
                                    label="Visibility"
                                    onChange={(e) => setVisibilityFilter(e.target.value)}
                                >
                                    <MenuItem value="all">All</MenuItem>
                                    <MenuItem value="public">Public</MenuItem>
                                    <MenuItem value="private">Private</MenuItem>
                                </Select>
                            </FormControl>

                            {organizations.length > 0 && (
                                <FormControl size="small" sx={{ minWidth: 120 }}>
                                    <InputLabel>Organization</InputLabel>
                                    <Select
                                        value={selectedOrg}
                                        label="Organization"
                                        onChange={(e) => setSelectedOrg(e.target.value)}
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        {organizations.map(org => (
                                            <MenuItem key={org.id} value={org.login}>
                                                {org.login}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                {filterRepos(repos).map((repo) => (
                    <Grid size={3}
                        key={repo.id}
                        onClick={() => navigate(`/repos/${repo.id}`)}>
                        <Card elevation={3} className="repoCard">
                            <CardContent>
                                <Box>
                                    {repo.private && (
                                        <Chip
                                            size="small"
                                            label="Private"
                                            color="default"
                                        />
                                    )}
                                </Box>
                                <Box className="cardTitle">
                                    <h5>{repo.full_name}</h5>
                                </Box>
                                <Box>
                                    <p className="cardDesc">{repo.description || 'No description available'}</p>
                                </Box>
                                <Box>
                                    {repo.repo_type === 'organization' && (
                                        <Chip
                                            size="small"
                                            label={repo.org_name}
                                            icon={<BusinessIcon />}
                                        />
                                    )}
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    );
}

export default DashboardScreen
