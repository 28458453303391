import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import Screen404 from "./components/Screen404/Screen404";
import Layout from "./layouts/dashboard";
import DashboardScreen from "./screens/DashboardScreen/DashboardScreen";
import SignupScreen from "./screens/SignupScreen/SignupScreen";
import LoginScreen from "./screens/LoginScreen/LoginScreen";
import GithubScreen from "./screens/GithubScreen/GithubScreen";
import HiddenLayout from "./layouts/dashboard-hidden";
import LogoutScreen from "./screens/LogoutScreen/LogoutScreen";
import RepositoryScreen from "./screens/RepositoryScreen/RepositoryScreen";
import RunScreen from "./screens/RunScreen/RunScreen";
import PBJRedactedAssessmentScreen from "./screens/PBJRedactedAssessmentScreen/PBJRedactedAssessmentScreen";

const router = createBrowserRouter([
    {
        Component: App, // root layout route
        errorElement: Screen404(),
        children: [
            {
                path: '/audit',
                children: [
                    {
                        path: 'redacted',
                        children: [{
                            path: '4',
                            Component: PBJRedactedAssessmentScreen
                        }]
                    }
                ]
            },
            {
                path: '/',
                Component: Layout,
                children: [
                    {
                        path: 'dashboard',
                        Component: DashboardScreen
                    },
                    {
                        path: 'github',
                        Component: GithubScreen
                    },
                    {
                        path: 'account',
                        children: [
                            {
                                path: 'logout',
                                Component: LogoutScreen
                            }
                        ]
                    },
                    {
                        path: 'repos',
                        children: [
                            {
                                path: ':repo_id',
                                Component: RepositoryScreen
                            }
                        ]
                    },
                    {
                        path: 'runs',
                        children: [
                            {
                                path: ':run_id',
                                children: [
                                    {
                                        path: 'repo',
                                        children: [
                                            {
                                                path: ':repo_id',
                                                Component: RunScreen
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                    
                ]
            },
            {
                path: '/auth',
                Component: HiddenLayout,
                children: [
                    {
                        path: 'login',
                        Component: LoginScreen
                    },
                    {
                        path: 'signup',
                        Component: SignupScreen
                    }
                ]

            }
        ]
    }
]);

export default router