import React, { ReactElement } from 'react'
import {JSX, PropsWithChildren, useState} from 'react'
import Grid from '@mui/material/Grid';
import AssessmentTab, { AssessmentTabProps } from '../AssessmentTab/AssessmentTab'
import { sanitizeForId } from '../../utils/stringUtils'


type AssessmentTabListProps = PropsWithChildren<{
    activeTabIndex: number
}>

const selectRiskClass = (score: number | 'N/A') => {
    if (score === 'N/A') {
        return 'ten'
    } else if (score <= 10) {
        return 'ten'
    } else if (score <= 20) {
        return 'twenty'
    } else if (score <= 30) {
        return 'thirty'
    } else if (score <= 40) {
        return 'forty'
    } else if (score <= 50) {
        return 'fifty'
    } else if (score <= 60) {
        return 'sixty'
    } else if (score <= 70) {
        return 'seventy'
    } else if (score <= 80) {
        return 'eighty'
    } else {
        return 'ninety'
    }
}

const AssessmentTabList = (props: AssessmentTabListProps): JSX.Element => {
    const [activeTab, setActiveTab] = useState(props.activeTabIndex)
    const handleTabClick = (index: number) => {
        setActiveTab(index);
    };

    const tabs = React.Children.toArray(props.children).filter(
        (child): child is ReactElement<AssessmentTabProps> =>
            React.isValidElement(child) && child.type === AssessmentTab
    );
    return (
        <Grid className="tabs" item xs={12} md={10}>
            <ul className="tab-list" role="tablist" aria-orientation="horizontal">
                {tabs.map((tab, index) => (
                    <li key={`tab-${index}`}>
                        <div
                            key={`tab-btn-${index}`}
                            role="tab"
                            id={`tab-${sanitizeForId(tab.props.label)}`}
                            aria-controls={`panel-${sanitizeForId(tab.props.label)}`}
                            aria-selected={activeTab === index}
                            onClick={() => handleTabClick(index)}
                            className={`tab-btn ${
                                activeTab === index && "tab-btn--active"
                            }`}
                        >{tab.props.label}<span className={`riskScore-sm ${selectRiskClass(tab.props.score)}-bg`}><span>{tab.props.score}</span></span></div>
                    </li>
                ))}
            </ul>
            {tabs[activeTab]}
        </Grid>
    );
}

export default AssessmentTabList